<template>
  <table class="pure-table pure-table-bordered">
    <tr>
      <th></th>
      <th
        v-for="(_, i) in conflicts"
        :key="i"
        :style="`${selected == i ? 'background-color: rgba(10, 10, 250, 0.2)' : ''}`"
      >
        技术矛盾{{ i + 1 }}
        <!-- <v-btn v-show="!locked" dense class="ma-2" tile outlined color="error" @click="removeConflict(i)">
          <v-icon left>mdi-minus</v-icon> 删除
        </v-btn> -->
      </th>
    </tr>
    <tr>
      <td style="text-align: center; width: 120px; height: 50px">如果</td>
      <td
        class="Triz_textarea"
        v-for="(conflict, i) in conflicts"
        :key="i"
        :style="`${selected == i ? 'background-color: rgba(10, 10, 250, 0.2)' : ''}`"
      >
        <triz-validator rules="required" id="技术矛盾-关键问题-如果">
          <triz-textarea v-model="conflict.c_if" @input="change"></triz-textarea>
        </triz-validator>
      </td>
    </tr>
    <tr>
      <td style="text-align: center; height: 50px">那么</td>
      <td
        class="Triz_textarea"
        v-for="(conflict, i) in conflicts"
        :key="i"
        :style="`${selected == i ? 'background-color: rgba(10, 10, 250, 0.2)' : ''}`"
      >
        <triz-validator rules="required" id="技术矛盾-关键问题-那么">
          <triz-textarea v-model="conflict.c_then" @input="change" placeholder="【改善了什么？】"></triz-textarea>
        </triz-validator>
      </td>
    </tr>
    <tr>
      <td style="text-align: center; height: 50px">但是</td>
      <td
        class="Triz_textarea"
        v-for="(conflict, i) in conflicts"
        :key="i"
        :style="`${selected == i ? 'background-color: rgba(10, 10, 250, 0.2)' : ''}`"
      >
        <triz-validator rules="required" id="技术矛盾-关键问题-但是">
          <triz-textarea v-model="conflict.but" @input="change" placeholder="【恶化了什么？】"></triz-textarea>
        </triz-validator>
      </td>
    </tr>
    <tr>
      <td style="text-align: center; height: 50px">改善的参数</td>
      <td
        class="Triz_Select-table"
        v-for="(conflict, i) in conflicts"
        :key="i"
        :style="`${selected == i ? 'background-color: rgba(10, 10, 250, 0.2)' : ''}`"
      >
        <triz-validator rules="required" id="技术矛盾-关键问题-改善的参数">
          <triz-muti-select
            v-model="conflict.good"
            flat
            :items="parameters.map((ele) => ({ value: ele.value, text: `${ele.value}.${ele.text}` }))"
            solo
            @change="change"
          >
          </triz-muti-select>
        </triz-validator>
      </td>
    </tr>
    <tr>
      <td style="text-align: center; height: 50px">恶化的参数</td>
      <td
        class="Triz_Select-table"
        v-for="(conflict, i) in conflicts"
        :key="i"
        :style="`${selected == i ? 'background-color: rgba(10, 10, 250, 0.2)' : ''}`"
      >
        <triz-validator rules="required" id="技术矛盾-关键问题-恶化的参数">
          <triz-muti-select
            v-model="conflict.bad"
            flat
            :items="parameters.map((ele) => ({ value: ele.value, text: `${ele.value}.${ele.text}` }))"
            solo
            @change="change"
          >
          </triz-muti-select>
        </triz-validator>
      </td>
    </tr>
  </table>
</template>
<script>
import { parameters } from '../store/altshuller'

export default {
  props: {
    conflicts: {
      type: Array,
      default: () => [],
    },
    selected: {
      type: Number,
      default: -1,
    },
  },
  model: {
    prop: 'conflicts',
    event: 'change',
  },
  data() {
    return {
      parameters,
    }
  },
  computed: {
    locked() {
      return this.$store.state.locked
    },
  },
  // watch: {
  //   conflicts(value) {
  //     this.$emit('change', value)
  //   },
  // },
  mounted() {},
  methods: {
    addConflict() {
      this.conflicts.push({
        c_if: '',
        c_then: '',
        but: '',
        good: [],
        bad: [],
      })
      this.conflicts.push({
        c_if: '',
        c_then: '',
        but: '',
        good: [],
        bad: [],
      })
      this.change()
    },
    removeConflict(index) {
      this.conflicts.splice(index, 1)
      this.change()
    },
    change() {
      this.$emit('change', this.conflicts)
    },
  },
}
</script>
<style scoped></style>
