<template>
  <table>
    <tr style="height: 50px">
      <th>组件</th>
      <th>功能</th>
      <th>功能等级</th>
      <th>裁剪规则</th>
      <th>新载体</th>
      <th>裁剪问题</th>
    </tr>
    <template v-for="(select, i) in current.selected">
      <template
        v-for="(relation, j) in relations
          .filter((relation) => !relation.isHide)
          .filter((relation) => relation.from == select.id)"
      >
        <tr :key="`selected-${i}-relation-${j}`">
          <td
            v-if="j == 0"
            :rowspan="
              relations.filter((relation) => !relation.isHide).filter((relation) => relation.from == select.id).length
            "
          >
            {{ nodes.find((node) => node.id === select.id).text }}
          </td>

          <td class="Triz_textarea">
            {{ relation.text }}
          </td>
          <td class="Triz_textarea">
            {{ relation.level }}
          </td>
          <td class="Triz_textarea">
            <triz-validator rules="required" id="功能裁剪-裁剪规则">
              <template v-if="relation.rule == '有害功能'">{{ relation.rule }}</template>
              <template v-if="relation.rule != '有害功能'"
                ><triz-select solo v-model="relation.rule" :items="clipRules" @change="change"> </triz-select
              ></template>
            </triz-validator>
          </td>
          <td class="Triz_textarea">
            <triz-validator rules="required" id="功能裁剪-新载体">
              <triz-select
                v-if="relation.rule == '裁剪规则C'"
                solo
                v-model="relation.new"
                :items="
                  nodes
                    .filter((node) => !current.selected.map((select) => select.id).includes(node.id))
                    .filter((node) => node.id != relation.to)
                    .map((node) => ({ value: node.id, text: node.text }))
                "
                @change="change"
              >
              </triz-select>
            </triz-validator>
          </td>
          <td class="Triz_textarea">
            <triz-validator rules="required" id="功能裁剪-裁剪问题">
              <triz-textarea v-model="relation.problem" @input="change"></triz-textarea>
            </triz-validator>
          </td>
        </tr>
      </template>
    </template>
  </table>
</template>
<script>
import { functionScores, clipRules } from '../store/default'

export default {
  props: {
    nodes: {
      type: Array,
      default: () => [],
    },
    relations: {
      type: Array,
      default: () => [],
    },
    current: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      functionScores,
      clipRules,
    }
  },
  model: {
    prop: 'current',
    event: 'change',
  },
  computed: {
    locked() {
      return this.$store.state.locked
    },
  },
  mounted() {},
  methods: {
    change() {
      this.$emit('change', this.current)
    },
  },
}
</script>
