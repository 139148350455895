<template>
  <table>
    <tr style="height: 80px">
      <th>序号</th>
      <th>缺陷</th>
      <th>详细</th>
      <!-- <th v-show="!locked">
        <v-btn
          v-show="!locked"
          dense
          class="ma-2"
          style="margin-left: 20px; border-radius: 4px"
          tile
          outlined
          color="success"
          @click="addIssue()"
        >
          <v-icon left>mdi-plus</v-icon> 添加缺陷
        </v-btn>
      </th> -->
    </tr>
    <template
      v-for="(relation, i) in relations
        .filter((relation) => !relation.isHide)
        .filter(
          (relation) =>
            relation.lineShape == lineShapes.bad ||
            relation.lineShape == lineShapes.unsatisfy ||
            relation.lineShape == lineShapes.over
        )"
    >
      <tr style="text-align: center" :key="i">
        <td>
          {{ i + 1 }}
        </td>
        <td class="Triz_textarea">
          {{
            `${nodes.find((node) => node.id == relation.from).text}${relation.text}${
              nodes.find((node) => node.id == relation.to).text
            }功能${lineShapesText[relation.lineShape]}`
          }}
        </td>
        <td class="Triz_textarea">
          <triz-validator rules="required" id="功能分析-缺陷表">
            <triz-textarea v-model="relation.issue" @input="change"></triz-textarea>
          </triz-validator>
        </td>
      </tr>
    </template>
  </table>
</template>
<script>
import { lineShapes, lineShapesText } from '../store/default'

export default {
  props: {
    nodes: {
      type: Array,
      default: () => [],
    },
    relations: {
      type: Array,
      default: () => [],
    },
  },
  model: {
    prop: 'relations',
    event: 'change',
  },
  data() {
    return {
      lineShapesText,
      lineShapes,
    }
  },
  computed: {
    locked() {
      return this.$store.state.locked
    },
  },
  mounted() {},
  methods: {
    change() {
      this.$emit('change', this.relations)
    },
  },
}
</script>
<style scoped></style>
