<template>
  <div id="ReasonTreeId" :style="`height:${containerHeight}px; width: 980px; border: 1px solid #eee`">
    <div v-if="locked" style="position: absolute; width: 980px; height: 800px; z-index: 99"></div>
    <zm-tree-org
      ref="reasonTree"
      @container-height-change="changeHeight"
      :data="data"
      :toolBar="false"
      :disabled="locked"
      :horizontal="false"
      :collapsable="false"
      :label-style="style"
      :node-draggable="true"
      :only-one-node="true"
      :clone-node-drag="true"
      :node-draging="nodeDragMove"
      :node-drag-end="nodeDragEnd"
      @on-expand="onExpand"
      @on-node-click="change"
      @on-node-copy="onNodeCopy"
      @on-logic-change="change"
      @on-node-added="change"
      @on-node-blur="change"
    >
      <!-- 自定义节点内容 -->
      <template slot-scope="{ node }">
        <v-hover v-slot:default="{ hover }">
          <v-card
            :color="node.selected ? 'red' : node.resolved ? 'green' : 'grey lighten-2'"
            :elevation="hover ? 10 : 1"
            :class="{ 'on-hover': hover }"
            class="mx-auto scope_content"
            :max-width="100"
            :min-width="100"
            style="font-size: 14px"
          >
            <strong style="font-size: 10px">{{ getLevelIndex(node) }}</strong
            ><br />{{ node.label }}
			<span class="arrow" v-if='node.children && node.children.length>0'></span>
          </v-card>
        </v-hover>
      </template>
    </zm-tree-org>
  </div>
</template>
<script>
import html2canvas from 'html2canvas'

export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      depth: 0,
      style: {
        background: '#fff',
        color: '#5e6d82',
      },
      treeImage: '',
      containerHeight: 500,
    }
  },
  model: {
    prop: 'data',
    event: 'change',
  },
  // watch: {
  //   data: {
  //     handler(val) {
  //       if (val) {
  //         this.$emit('change', val)
  //         // this.toImage()
  //       }
  //     },
  //     deep: true,
  //   },
  // },
  computed: {
    locked() {
      return this.$store.state.locked
    },
  },
  mounted() {
    this.toggleExpand(this.data, this.expandAll)
    // this.toImage()
    this.$refs.reasonTree.refreshResolve()
  },
  methods: {
    changeHeight(height) {
      this.containerHeight = height
    },
    toImage() {
      html2canvas(this.$refs.reasonTree.$el, {}).then((canvas) => {
        this.treeImage = canvas.toDataURL()
      })
    },
    getDeep(data, i, deepArr) {
      //获取当前结点的子数组，并且打印当前结点的值
      var treeRoot = data.children
      //如果当前结点没有子数组了（没有子结点）就跳出当前递归,并且使计数器+1，并把计数器i的值存入深度数组中
      if (!treeRoot) {
        i++
        deepArr.push(i)
        return
      }
      //如果当前结点有子数组，就要使计数器+1
      i++
      //通过for循环来找出每一条路径，对遍历到的结点使用递归
      for (let j = 0; j < treeRoot.length; j++) {
        this.getDeep(treeRoot[j], i, deepArr) //递归时传入的就是当前结点的第j个子结点，当这第j个子结点中的所有子孙结点全部遍历完成之后，再去遍历第j+1个结点的所有子孙结点
      }
    },
    onExpand(e, data) {},
    onLogicChange(e, data) {},
    nodeDragMove(data) {},
    nodeDragEnd(data, isSelf) {},
    onNodeClick(e, data) {},
    change() {
      this.$emit('change', this.data)
    },
    onNodeCopy() {},
    handleNodeAdd(node) {},
    expandChange() {
      this.toggleExpand('expandChange', this.data, this.expandAll)
    },
    toggleExpand(data, val) {
      if (Array.isArray(data)) {
        data.forEach((item) => {
          this.$set(item, 'expand', val)
          if (item.children) {
            this.toggleExpand(item.children, val)
          }
        })
      } else {
        this.$set(data, 'expand', val)
        if (data.children) {
          this.toggleExpand(data.children, val)
        }
      }
    },
    getLevelIndex(node) {
      if (node.root) return '0'
      const result = []
      this.getLabel(this.data.children, node, result)
      const label = result.join('.')
      node.prefix = label
      return label
    },
    getLabel(children, node, result) {
      for (let i = 0; i < children.length; i++) {
        result.push(`${i + 1}`)
        const child = children[i]
        if (child.id === node.id) {
          return true
        }
        if (child.children) {
          const ok = this.getLabel(child.children, node, result)
          if (ok) return ok
        }
      }
      result.pop()
      return false
    },
  },
}
</script>
