<template>
  <v-container style="font-size: 24px" class="special_p">
    <v-row>
      <p>将此技术矛盾转化为物理矛盾，即同一个参数具有相反的并且合乎情理的需求。</p>
    </v-row>
    <template v-for="(physical, i) in conflict.physicalConflicts">
      <v-row :key="`title-${i}`">
        <p>
          <strong>{{ i + 1 }}.物理矛盾{{ i + 1 }}</strong>
          <v-btn v-show="!locked" dense class="ma-2" tile outlined color="error" @click="removePhysical(i)">
            <v-icon left>mdi-minus</v-icon> 删除
          </v-btn>
        </p>
      </v-row>
      <v-row :key="`physical-${i}`">
        <triz-validator rules="required" id="物理矛盾-关键问题-问题描述">
          <p v-if="conflict.type != 'physical'">
            <strong>描述关键问题：</strong>
            {{techConflicts}}
          </p>
          <p v-if="conflict.type == 'physical'">
            <strong>描述关键问题：</strong>
            <InnerTextField @input="change" v-model="physical.keyProblem" />
          </p>
        </triz-validator>
      </v-row>

      <v-row :key="`conflict-${i}`">
        <triz-validator rules="required" id="物理矛盾-关键问题-矛盾描述">
          <p><strong>写出物理矛盾：</strong></p>
        </triz-validator>
        <ul>
          <li>
            <triz-validator rules="required" id="物理矛盾-关键问题-矛盾描述">
              <InnerTextField @input="change" v-model="physical.goodA" />
            </triz-validator>
            要
            <triz-validator rules="required" id="物理矛盾-关键问题-矛盾描述">
              <InnerTextField @input="change" v-model="physical.goodB" /> </triz-validator
            >， 因为
            <triz-validator rules="required" id="物理矛盾-关键问题-矛盾描述">
              <InnerTextField @input="change" v-model="physical.goodReason" />
            </triz-validator>
          </li>
          <li>但是</li>
          <li>
            <triz-validator rules="required" id="物理矛盾-关键问题-矛盾描述">
              <InnerTextField @input="change" v-model="physical.badA" />
            </triz-validator>
            要
            <triz-validator rules="required" id="物理矛盾-关键问题-矛盾描述">
              <InnerTextField @input="change" v-model="physical.badB" /> </triz-validator
            >， 因为
            <triz-validator rules="required" id="物理矛盾-关键问题-矛盾描述">
              <InnerTextField @input="change" v-model="physical.badReason" />
            </triz-validator>
          </li>
        </ul>
      </v-row>
      <v-row :key="`method-${i}`">
        <strong>可以通过</strong>
        <triz-validator rules="required" id="物理矛盾-关键问题-解决方法">
          <triz-select
            v-model="physical.method"
            :table="false"
            label="请选择"
            flat
            :items="methods"
            solo
            @change="change"
          ></triz-select>
        </triz-validator>
        来解决这个物理矛盾
        <!-- <v-btn v-show="!locked" dense class="ma-2" tile outlined color="error" @click="removeMethod(i, j, physical)">
            <v-icon left>mdi-minus</v-icon> 删除
          </v-btn> -->
        <v-btn
          v-show="!locked && physical.method == '分离矛盾'"
          dense
          class="ma-2"
          tile
          outlined
          color="success"
          @click="addKeyWord(physical)"
        >
          <v-icon left>mdi-plus</v-icon> 添加关键词
        </v-btn>
      </v-row>
      <template v-for="(keyWord, k) in physical.keyWords">
        <v-row :key="`physical-conflict-${i}-keyword-${k}`">
          “
          <triz-validator rules="required" id="物理矛盾-关键问题-导向关键词">
            <triz-select
              label="请选择"
              v-model="keyWord.keyWord"
              flat
              :items="keyWords"
              solo
              @change="changeKeyWord(physical, keyWord)"
            ></triz-select>
          </triz-validator>
          ”：{{
            keyWords.find((ele) => ele.value === keyWord.keyWord)
              ? keyWords.find((ele) => ele.value === keyWord.keyWord).resolve
              : ''
          }}
          <v-btn v-show="!locked" dense class="ma-2" tile outlined color="error" @click="removeKeyWord(physical, k)">
            <v-icon left>mdi-minus</v-icon> 删除
          </v-btn>
        </v-row>
      </template>

      <!-- <template v-for="(method, j) in physical.methods">
        <v-row :key="`conflict-${j}`">
        <triz-validator rules="required" id="物理矛盾-关键问题-矛盾描述">
          <p><strong>写出物理矛盾：</strong></p>
        </triz-validator>
        <ul>
          <li>
            <triz-validator rules="required" id="物理矛盾-关键问题-矛盾描述">
              <InnerTextField @input="change" v-model="method.goodA" />
            </triz-validator>
            要
            <triz-validator rules="required" id="物理矛盾-关键问题-矛盾描述">
              <InnerTextField @input="change" v-model="method.goodB" /> </triz-validator
            >， 因为
            <triz-validator rules="required" id="物理矛盾-关键问题-矛盾描述">
              <InnerTextField @input="change" v-model="method.goodReason" />
            </triz-validator>
          </li>
          <li>但是</li>
          <li>
            <triz-validator rules="required" id="物理矛盾-关键问题-矛盾描述">
              <InnerTextField @input="change" v-model="method.badA" />
            </triz-validator>
            要
            <triz-validator rules="required" id="物理矛盾-关键问题-矛盾描述">
              <InnerTextField @input="change" v-model="method.badB" /> </triz-validator
            >， 因为
            <triz-validator rules="required" id="物理矛盾-关键问题-矛盾描述">
              <InnerTextField @input="change" v-model="method.badReason" />
            </triz-validator>
          </li>
        </ul>
      </v-row>
        <v-row :key="`method-${j}`">
          <strong>可以通过</strong>
          <triz-validator rules="required" id="物理矛盾-关键问题-解决方法">
            <triz-select
              v-model="method.method"
              :table="false"
              label="请选择"
              flat
              :items="methods"
              solo
              @change="changeMethod(physical, method)"
            ></triz-select>
          </triz-validator>
          来解决这个物理矛盾
          <v-btn v-show="!locked" dense class="ma-2" tile outlined color="error" @click="removeMethod(i, j, physical)">
            <v-icon left>mdi-minus</v-icon> 删除
          </v-btn>
          <v-btn v-show="!locked && method.method == '分离矛盾'" dense class="ma-2" tile outlined color="success" @click="addKeyWord(i, j)">
            <v-icon left>mdi-plus</v-icon> 添加关键词
          </v-btn>
        </v-row>
        <template v-for="(keyWord, k) in method.keyWords">
          <v-row :key="`physical-conflict-${i}-method-${j}-keyword-${k}`">
            “
            <triz-validator rules="required" id="物理矛盾-关键问题-导向关键词">
              <triz-select
                label="请选择"
                v-model="keyWord.keyWord"
                flat
                :items="keyWords"
                solo
                @change="changeKeyWord(physical, keyWord)"
              ></triz-select>
            </triz-validator>
            ”：{{
              keyWords.find((ele) => ele.value === keyWord.keyWord)
                ? keyWords.find((ele) => ele.value === keyWord.keyWord).resolve
                : ''
            }}
            <v-btn v-show="!locked" dense class="ma-2" tile outlined color="error" @click="removeKeyWord(i, j, k, physical)">
              <v-icon left>mdi-minus</v-icon> 删除
            </v-btn>
          </v-row>
        </template>
      </template> -->

      <!-- <v-row :key="`method-add-${i}`">
        <v-btn v-show="!locked" dense class="ma-2" tile outlined color="success" @click="addMethod(i)">
          <v-icon left>mdi-plus</v-icon> 添加方法
        </v-btn>
      </v-row> -->

      <template>
        <v-row v-if="physical.principles && physical.principles.length" :key="`principle-text-${i}`">
          <p><strong>对应的发明原理：</strong></p>
        </v-row>
        <template v-for="(principle, i) in physical.principles">
          <v-row :key="`principle-${i}`">
            <p>（{{ i + 1 }}）{{ principle.value }}.{{ principle.text }}</p>
          </v-row>
		  <v-row>
		  	<p><strong style="margin-left: 50px;">原理定义</strong></p>
		  </v-row>
		  <v-row class="principle_size">
		  	<p style="margin-left: 50px;">{{principles[principle.value-1].definition }}</p>
		  </v-row>
		  <v-row>
		  	<p><strong style="margin-left: 50px;">原理内容</strong></p>
		  </v-row>
          <v-row :key="`principle-detail-${i}`" class="principle_size">
            <ol>
              <p v-for="detail in principle.details" :key="detail">{{ detail }}</p>
            </ol>
          </v-row>
        </template>
      </template>
      <template v-for="(solution, j) in physical.solutions">
        <v-row :key="`selectedPrinciple-2-${i}-${j}`">
          <p>
            <template
              >根据发明原理 {{ solution.selectedPrinciple }} -
              <triz-validator rules="required" id="技术矛盾-关键问题-发明原理选择">
                <triz-select
                  label="请选择"
                  :table="false"
                  solo
                  v-model="solution.selectedPrinciple"
                  flat
                  :items="physical.principles"
                  @change="change"
                />
              </triz-validator>
              以及其中的指导原则
              <triz-validator rules="required" id="技术矛盾-关键问题-发明原理子项选择">
                <triz-select
                  label="请选择"
                  :table="false"
                  solo
                  v-model="solution.selectedPrincipleDetail"
                  flat
                  :items="
                    physical.principles.find((ele) => ele.value === solution.selectedPrinciple)
                      ? physical.principles.find((ele) => ele.value === solution.selectedPrinciple).details
                      : []
                  "
                  @change="change"
                />
              </triz-validator>
            </template>
            <span>我们得出了方案：</span>
            <v-btn v-show="!locked" dense class="ma-2" tile outlined color="error" @click="removeSolution(physical, j)">
              <v-icon left>mdi-minus</v-icon> 删除
            </v-btn>
          </p>
        </v-row>
        <v-row :key="`detail-title-2-${i}-${j}`"> <strong>基于物理矛盾的解决方案{{ j + 1 }}</strong> </v-row>
        <v-row :key="`detail-rich-2-${i}-${j}`">
          <v-col style="padding: 12px 12px 12px 0">
            <triz-validator rules="required" id="技术矛盾-关键问题-解决方案说明">
              <Tinymce @input="change" v-model="solution.solutionDetail" placeholder="请输入解决方案" />
            </triz-validator>
          </v-col>
        </v-row>
      </template>
      <v-row :key="`resolution-2-${i}`">
        <v-btn v-show="!locked" dense class="ma-2" tile outlined color="success" @click="addSolution(physical, i)">
          <v-icon left>mdi-plus</v-icon> 添加物理解决方案
        </v-btn>
      </v-row>
    </template>
    <p>
      <v-btn v-show="!locked" dense class="ma-2" tile outlined color="success" @click="addPhysical">
        <v-icon left>mdi-plus</v-icon> 添加物理矛盾
      </v-btn>
    </p>
  </v-container>
</template>

<script>
import Tinymce from '@/components/Tinymce'
import InnerTextField from '@/components/InnerTextField'
import { physicalConflict, keyWords, methods } from '../../../store/default'
import { principles } from '../../../store/altshuller'
import Technical from '@/views/triz/component/Technical'

export default {
  components: {
    InnerTextField,
    Tinymce,
  },
  props: {
    triz: {
      type: Object,
      default: () => ({}),
    },
    keyProblem: {
      type: Object,
      default: () => ({}),
    },
    conflicts: {
      type: Array,
      default: () => [],
    },
    conflict: {
      type: Object,
      default: () => ({}),
    },
  },
  model: {
    prop: 'triz',
    event: 'change',
  },
  data() {
    return {
      // triz: this.triz,
      physicalConflict,
      keyWords,
      methods,
      principles,
    }
  },
  computed: {
    locked() {
      return this.$store.state.locked
    },
    techConflicts() {
      const technicalConflicts = this.conflict.technicalConflicts
      const conflict1 = technicalConflicts[0]
        const conflict2 = technicalConflicts[1]
        return `应该${conflict1.c_if}因为${conflict1.c_then}, 应该${conflict2.c_if}因为${conflict2.c_then}`
    },
  },
  mounted() {},
  methods: {
    addPhysical() {
      const id = `physical-${new Date().getTime()}`
      this.conflict.physicalConflicts.push({
        id,
        keyProblem: '',
        conflictDetail: {
          goodA: '',
          goodB: '',
          goodReason: '',
          badA: '',
          badB: '',
          badReason: '',
        },
        keyWords: [],
        principles: [],
        solutions: [],
      })
      this.change()
    },
    removePhysical(index) {
      const physical = this.conflict.physicalConflicts[index]
      physical.solutions.forEach((solution) => {
        this.triz.solutionScores.splice(
          this.triz.solutionScores.findIndex((ele) => ele.id === solution.id),
          1
        )
      })
      this.conflict.physicalConflicts.splice(index, 1)
      this.change()
    },
    addKeyWord(physical) {
      const id = `keyword-${new Date().getTime()}`
      physical.keyWords.push({
        id,
        keyWord: 0,
        keyWordDescription: '',
      })
      this.change()
    },
    removeKeyWord(physical, k) {
      physical.keyWords.splice(k, 1)
      conflict.principles = this.refreshPriciples(conflict)
      this.change()
    },
    addSolution(physical) {
      const id = `physical-${new Date().getTime()}`
      physical.solutions.push({
        id,
        selectedPrinciple: '',
        solutionDetail: '',
      })
      this.triz.solutionScores.push({
        id,
      })
      this.change()
    },
    removeSolution(physical, j) {
      const [{ id }] = physical.solutions.splice(j, 1)
      this.triz.solutionScores.splice(
        this.triz.solutionScores.findIndex((ele) => ele.id === id),
        1
      )
      this.change()
    },
    changeKeyWord(physical, keyWord) {
      const item = this.keyWords.find((ele) => ele.value == keyWord.keyWord)
      if (!item) {
        physical.principles = []
        this.change()
        return
      }
      physical.principles = this.refreshPriciples(physical)
      this.change()
    },
    // changeMethod(conflict, method) {
    //   const item = this.methods.find((ele) => ele.value == method.method)
    //   if (!item || !item.suggestion) {
    //     conflict.principles = []
    //   }
    //   conflict.principles = this.refreshPriciples(conflict)
    //   this.change()
    // },
    refreshPriciples(physical) {
      const keyWords = []
      if (physical.method == '分离矛盾') {
        keyWords.push(...physical.keyWords.map((keyWord) => keyWord.keyWord))
      }
      const suggestions = keyWords.flatMap((keyWord) => this.keyWords.find((ele) => ele.value == keyWord).suggestion)
      if (physical.method == '满足矛盾')
        suggestions.push(...this.methods.find((method) => method.value == '满足矛盾').suggestion)
      const principleIds = Array.from(new Set(suggestions))
      console.log('changeKeyWord', principleIds)
      return this.principles.filter((ele) => principleIds.includes(ele.value))
    },
    change() {
      this.$emit('change', this.triz)
    },
  },
}
</script>
<style lang="scss">
.principle_size {
  margin-top: 0 !important;
  p {
    font-size: 18px !important;
    text-indent: 30px !important;
    margin-bottom: 0px !important;
    line-height: 20px !important;
  }
}
</style>
