<template>
  <table class="pure-table pure-table-bordered">
    <tr style="height: 50px">
      <th>资源类型</th>
      <th>价值</th>
      <th>数量</th>
      <th>质量</th>
      <th>可用性</th>
      <th v-show="!locked">操作</th>
    </tr>
    <tr v-for="(resource, i) in resources" :key="i">
      <td class="Triz_textarea">
        <triz-validator rules="required" id="资源分析-已用资源分析表-资源类型">
          <triz-textarea v-model="resource.type" @input="change"></triz-textarea>
        </triz-validator>
      </td>
      <td class="Triz_Select-table">
        <triz-validator rules="required" id="资源分析-已用资源分析表-价值">
          <triz-select v-model="resource.value" flat :items="priceTypes" solo @change="change"> </triz-select>
        </triz-validator>
      </td>
      <td class="Triz_Select-table">
        <triz-validator rules="required" id="资源分析-已用资源分析表-数量">
          <triz-select v-model="resource.qauntity" flat :items="qauntityTypes" solo @change="change"> </triz-select>
        </triz-validator>
      </td>
      <td class="Triz_Select-table">
        <triz-validator rules="required" id="资源分析-已用资源分析表-质量">
          <triz-select v-model="resource.quality" flat :items="qualityTypes" solo @change="change"> </triz-select>
        </triz-validator>
      </td>
      <td class="Triz_Select-table">
        <triz-validator rules="required" id="资源分析-已用资源分析表-可用性">
          <triz-select v-model="resource.avilability" flat :items="avilabilityTypes" solo @change="change">
          </triz-select>
        </triz-validator>
      </td>
      <td v-show="!locked">
        <v-btn dense class="ma-2" tile outlined color="error" @click="removeResource(i)">
          <v-icon left>mdi-minus</v-icon> 删除
        </v-btn>
      </td>
    </tr>
    <tr v-show="!locked">
      <td colspan="6">
        <v-btn dense class="ma-2" tile outlined color="success" @click="addResource()">
          <v-icon left>mdi-plus</v-icon> 添加
        </v-btn>
      </td>
    </tr>
  </table>
</template>
<script>
import { priceTypes, qauntityTypes, qualityTypes, avilabilityTypes } from '../store/default'

export default {
  props: {
    resources: {
      type: Array,
      default: () => [],
    },
  },
  model: {
    prop: 'resources',
    event: 'change',
  },
  data() {
    return { priceTypes, qauntityTypes, qualityTypes, avilabilityTypes }
  },
  computed: {
    // 占位空格内容
    all() {
      return []
    },
    locked() {
      return this.$store.state.locked
    },
  },
  // watch: {
  //   resources: {
  //     handler(val) {
  //       this.$emit('change', val)
  //     },
  //     deep: true,
  //   },
  // },
  mounted() {},
  methods: {
    addResource() {
      this.resources.push({
        type: '',
        value: 0,
        qauntity: 0,
        quality: 0,
        avilability: 0,
      })
      this.change()
    },
    removeResource(i) {
      this.resources.splice(i, 1)
      this.change()
    },
    change() {
      this.$emit('change', this.resources)
    },
  },
}
</script>
<style scoped></style>
