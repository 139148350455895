<template>
  <v-container>
    <v-row align="center" justify="center">
      <ConflictTable v-model="conflict.technicalConflicts" :selected="conflict.selectedConflict" @change="change" />
    </v-row>
    <v-row align="center" justify="center"> 表 技术矛盾 </v-row>
    <v-row style="width: 100%">
      <p>
        <strong>确定发明原理：</strong>由于我们目标是解决
        <triz-validator rules="required" id="技术矛盾-关键问题-目标问题">
          <InnerTextField @input="change" v-model="conflict.slots.slot05" />
        </triz-validator>
        的主要问题，所以选择
        <triz-validator rules="required" id="技术矛盾-关键问题-技术矛盾选择">
          <triz-select
            :table="false"
            v-model="conflict.selectedConflict"
            flat
            solo
            :items="conflict.technicalConflicts?.map((_, i) => ({ text: `技术矛盾${i + 1}`, value: i })) ?? []"
            @change="change"
          />
        </triz-validator>
        。由阿奇舒勒矛盾矩阵表，可以查到可参考的发明原理如下：
      </p>
    </v-row>
    <v-row align="center" justify="center">
      <AltshullerMartrix
        :id="`altshuller-martrix-${conflict.id}`"
        :selected-conflict="conflict.technicalConflicts[conflict.selectedConflict]"
        v-model="conflict.principles"
        @change="changePrinciples"
      />
    </v-row>
    <v-row align="center" justify="center"> 表 阿奇舒勒矛盾矩阵 </v-row>
    <template v-for="(principle, i) in conflict.principles">
      <v-row :key="`tech-principle-${i}`" class="principle_size2">
        <p>（{{ i + 1 }}）{{ principle.value }}.{{ principle.text }}</p>
      </v-row>
	  <v-row>
	  	<p><strong >原理定义</strong></p>
	  </v-row>
	  <v-row class="principle_size">
	  	<p style="margin-left: 20px;">{{principles[principle.value-1].definition }}</p>
	  </v-row>
	  <v-row>
	  	<p><strong >原理内容</strong></p>
	  </v-row>
      <v-row :key="`tech-principle-detail-${i}`" class="principle_size">
        <ol>
          <p v-for="detail in principle.details" :key="detail">{{ detail }}</p>
        </ol>
      </v-row>
    </template>
    <template v-for="(solution, j) in conflict.solutions">
      <div :key="`conflict-solutions-${j}`">
        <v-row>
          <strong>基于技术矛盾的解决方案{{ j + 1 }}</strong>
          <v-btn
            v-show="!locked"
            dense
            class="ma-2"
            tile
            outlined
            color="error"
            @click="removeSolution(conflict, i, j)"
          >
            <v-icon left>mdi-minus</v-icon> 删除
          </v-btn>
        </v-row>
        <v-row>
          <p>
            根据发明原理 {{ solution.selectedPrinciple }} -
            <triz-validator rules="required" id="技术矛盾-关键问题-发明原理选择">
              <triz-select
                :table="false"
                label="请选择"
                solo
                v-model="solution.selectedPrinciple"
                flat
                :items="conflict.principles"
                @change="change"
              />
            </triz-validator>
            以及其中的指导原则
            <triz-validator rules="required" id="技术矛盾-关键问题-发明原理子项选择">
              <triz-select
                :table="false"
                label="请选择"
                solo
                v-model="solution.selectedPrincipleDetail"
                flat
                :items="
                  conflict.principles.find((ele) => ele.value === solution.selectedPrinciple)
                    ? conflict.principles.find((ele) => ele.value === solution.selectedPrinciple).details
                    : []
                "
                @change="change"
              />
            </triz-validator>
            我们得出了方案：
            <v-row>
              <v-col style="padding: 12px 12px 12px 0">
                <triz-validator rules="required" id="技术矛盾-关键问题-解决方案说明">
                  <Tinymce @input="change" v-model="solution.solutionDetail" placeholder="请输入解决方案" />
                </triz-validator>
              </v-col>
            </v-row>
          </p>
        </v-row>
      </div>
    </template>
    <v-row>
      <v-btn
        v-show="!locked"
        :disabled="!conflict.principles.length"
        dense
        class="ma-2"
        tile
        outlined
        color="success"
        @click="addSolution(conflict, i)"
      >
        <v-icon left>mdi-plus</v-icon> 添加技术解决方案
      </v-btn>
    </v-row>

    <v-btn
      :key="`change-physic`"
      v-if="!conflict.isPhysical"
      v-show="!locked"
      dense
      class="ma-2"
      tile
      outlined
      color="orange"
      @click="changeToPhysical(conflict, true)"
    >
      <v-icon left>mdi-refresh</v-icon> 转换到物理矛盾
    </v-btn>
    <v-btn
      :key="`change-tech`"
      v-if="conflict.isPhysical"
      v-show="!locked"
      dense
      class="ma-2"
      tile
      outlined
      color="orange"
      @click="changeToPhysical(conflict, false)"
    >
      <v-icon left>mdi-refresh</v-icon> 删除物理矛盾
    </v-btn>
    <v-row :key="`physic`">
      <v-row>
        <strong>转换为物理矛盾</strong>
      </v-row>
      <Physical1
        v-if="conflict.isPhysical"
        :conflicts="conflict.technicalConflicts"
        :triz="triz"
        :conflict="conflict"
        @change="change"
      />
    </v-row>
  </v-container>
</template>

<script>
import ConflictTable from '@/components/ConflictTable'
import Tinymce from '@/components/Tinymce'
import AltshullerMartrix from '@/components/AltshullerMartrix'
import InnerTextField from '@/components/InnerTextField'
import Physical1 from './Physical1.vue'
import { lineShapes } from '../../../store/default'
import {
		principles
	} from '@/store/altshuller'
export default {
  components: {
    ConflictTable,
    Tinymce,
    AltshullerMartrix,
    InnerTextField,
    Physical1,
  },
  props: {
    triz: {
      type: Object,
      default: () => ({}),
    },
    conflict: {
      type: Object,
      default: () => ({}),
    },
    parentid: {
      type: String,
      default: '',
    }
  },
  model: {
    prop: 'conflict',
    event: 'change',
  },
  data() {
    return {
      lineShapes,
	  principles
    }
  },
  computed: {
    locked() {
      return this.$store.state.locked
    },
  },
  mounted() {},
  methods: {
    change() {
      this.$emit('change', this.triz)
      this.$nextTick(() => {
        this.$forceUpdate()
      })
    },
    addSolution(conflict, i) {
      const id = `technical-${new Date().getTime()}`
      conflict.solutions.push({
        id,
        selectedPrinciple: '',
        selectedPrincipleDetail: '',
        solutionDetail: '',
      })
      this.triz.solutionScores.push({
        id,
      })
      this.change()
    },
    removeSolution(conflict, i, j) {
      const [{ id }] = conflict.solutions.splice(j, 1)
      this.triz.solutionScores.splice(
        this.triz.solutionScores.findIndex((ele) => ele.id === id),
        1
      )
      this.change()
    },
    changeToPhysical(conflict, isPhysical) {
      conflict.isPhysical = isPhysical
      this.change()
    },
    changePrinciples() {
      this.change()
    },
  },
}
</script>
<style lang="scss">
.principle_size2 {
  p {
    text-indent: 0 !important;
  }
}
.principle_size {
  margin-top: 0 !important;
  p {
    font-size: 18px !important;
    text-indent: 30px !important;
    margin-bottom: 0px !important;
  }
}
.margin_select {
  margin: 0 10px;
}
// .delete_right{
//   display: flex;
//   position: relative;
//   width: 100%;
//   .v-btn{
//     position: absolute;
//     right: 0;
//     margin-top: 0px!important;
//   }
// }
.Triz_Select_fujia {
  line-height: 46px !important;
  .v-select__selection {
    text-indent: 10px;
  }
}
</style>
