<template>
  <table class="pure-table pure-table-bordered">
    <tr>
      <th rowspan="2">资源类型</th>
      <th colspan="3" style="height: 50px">系统级别</th>
    </tr>
    <tr style="height: 50px">
      <th>子系统</th>
      <th>系统</th>
      <th>超系统</th>
    </tr>
    <tr v-for="(resource, i) in resources" :key="i">
      <td style="text-align: center">{{ resource.type }}</td>
      <td class="Triz_textarea" style="height: 50px">
        <triz-validator rules="required" id="资源分析-资源分析总表-子系统">
          <triz-textarea v-model="resource.child" @input="change" />
        </triz-validator>
      </td>
      <td class="Triz_textarea">
        <triz-validator rules="required" id="资源分析-资源分析总表-系统">
          <triz-textarea v-model="resource.system" @input="change" />
        </triz-validator>
      </td>
      <td class="Triz_textarea">
        <triz-validator rules="required" id="资源分析-资源分析总表-超系统">
          <triz-textarea v-model="resource.super" @input="change" />
        </triz-validator>
      </td>
    </tr>
  </table>
</template>
<script>
export default {
  props: {
    resources: {
      type: Array,
      default: () => [],
    },
  },
  model: {
    prop: 'resources',
    event: 'change',
  },
  data() {
    return {}
  },
  mounted() {},
  methods: {
    change() {
      this.$emit('change', this.resources)
    },
  },
}
</script>
<style scoped></style>
