<template>
  <table>
    <tr style="height: 50px">
      <th>序号</th>
      <th>关键缺点</th>
      <th>关键问题</th>
      <!-- <th>可能的解决方案</th> -->
      <th>矛盾描述</th>
      <!-- <th>
        <v-btn v-show="!locked" dense class="ma-2" tile outlined color="success" @click="addReason">
          <v-icon left>mdi-plus</v-icon> 添加缺点
        </v-btn>
      </th> -->
    </tr>
    <tr v-for="(reason, i) in list" :key="i">
      <td>
        {{ reason.prefix }}
      </td>
      <td>
         {{ reason.label }}
      </td>
      <td class="Triz_textarea">
        <triz-validator rules="required" id="因果关系表-关键问题">
          <triz-textarea v-model="reason.problem" @input="change" placeholder="【请输入关键缺点的反向】"></triz-textarea>
        </triz-validator>
      </td>
      <!-- <td class="Triz_textarea">
        <triz-validator rules="required" id="因果关系表-可能的解决方案">
          <triz-textarea v-model="reason.solution" @input="change"></triz-textarea>
        </triz-validator>
      </td> -->
      <td class="Triz_textarea">
        <triz-validator rules="required" id="因果关系表-矛盾描述">
          <triz-textarea v-model="reason.desc" @input="change"></triz-textarea>
        </triz-validator>
      </td>
      <!-- <td v-show="!locked" >
      <v-btn dense class="ma-2" tile outlined color="error" @click="removeReason(i)">
          <v-icon left>mdi-minus</v-icon> 删除
        </v-btn>
      </td> -->
    </tr>
  </table>
</template>
<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {}
  },
  computed: {
    locked() {
      return this.$store.state.locked
    },
  },
  mounted() {},
  methods: {
    change() {
      this.$emit('change', this.list)
    },
  },
}
</script>
