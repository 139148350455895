var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._l((_vm.postion),function(sub,i){return [_vm._l((sub),function(tail,j){return [_c('v-row',{key:`matrix-${i}-${j}`,attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticStyle:{"position":"relative","width":"980px"}},[_c('table',{attrs:{"cellpadding":"0","cellspacing":"0"}},[_c('tr',{style:(`background-color:#cccccc;`)},[_c('td'),_vm._l((_vm.parameters),function(parameter,i){return [(i > tail.y - 3 && i < tail.y + 3)?_c('td',{key:`title-col-${i}`},[_vm._v(" "+_vm._s(_vm.parameters[i].text)+" ")]):_vm._e()]})],2),_vm._l((_vm.matrix),function(row,i){return [(i > tail.x - 3 && i < tail.x + 3)?_c('tr',{key:`row-${i}`,staticStyle:{"height":"20px"}},[_c('td',{style:(`background-color:#cccccc;min-width:150px;`)},[_vm._v(" "+_vm._s(_vm.parameters[i].text)+" ")]),_vm._l((row),function(col,j){return [(j > tail.y - 3 && j < tail.y + 3)?_c('td',{key:`col-${j}`,staticClass:"AltshullerXY",staticStyle:{"word-wrap":"break-word"},style:(`background-color:${
                      tail.x === i && tail.y === j
                        ? 'green'
                        : tail.x === i || tail.y === j
                        ? 'pink'
                        : 'white'
                    }`)},[_vm._v(" "+_vm._s(col)+" ")]):_vm._e()]})],2):_vm._e()]})],2)])],1)]})]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }