<template>
  <table style="width: auto;">
    <tr v-for="(itemX, i) in all" :key="i">
      <td v-for="(itemY, j) in all" :key="j">
        <v-card
          class="d-flex justify-center align-center"
          width="60px"
          height="60px"
          outlined
          :color="
            relations[i][j] !== 0 && relations[j][i] !== 0 && relations[i][j] !== relations[j][i] ? 'red' : i === j ? '#cccccc' : 'white'
          "
        >
        <template v-if="i === 0 && j === 0">
            组件
          </template>
          <template v-if="i === 0">
            <div style="font-size:14px;">{{ itemY.text }}</div>
          </template>
          <template v-if="j === 0">
            <div style="font-size:14px;">{{ itemX.text }}</div>
            
          </template>
          <template v-if="i !== j && i !== 0 && j !== 0">
            <v-icon v-if="disabled && relations[i][j] !== 0">{{
              relations[i][j] === 1 ? 'mdi-plus' : 'mdi-minus'
            }}</v-icon>
            <v-btn
              v-if="!disabled"
              class="mx-2"
              fab
              depressed
              dark
              :color="
                relations[i][j] === 0
                  ? 'grey lighten-2'
                  : relations[i][j] === 1
                  ? 'green lighten-2'
                  : 'orange lighten-2'
              "
              @click="relationChange(i, j)"
              small
            >
              <v-icon v-if="relations[i][j] !== 0" dark>{{ relations[i][j] === 1 ? 'mdi-plus' : 'mdi-minus' }}</v-icon>
            </v-btn>
          </template>
        </v-card>
      </td>
    </tr>
  </table>
</template>
<script>
export default {
  props: {
    // 题目label
    items: {
      type: Array,
      default: () => [],
    },
    relations: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: true,
    },
  },
  model: {
    prop: 'relations',
    event: 'change',
  },
  data() {
    return {
      currI: 0,
      currJ: 0,
    }
  },
  computed: {
    // 占位空格内容
    all() {
      return ['', ...this.items]
    },
  },
  watch: {
    // items(val, old) {
    //   if (val.length > old.length) {
    //     const diff = val.findIndex((eleNew) => old.every((eleOld) => eleOld.id !== eleNew.id))
    //     this.relations.forEach((ele) => {
    //       ele.splice(diff + 1, 0, 0)
    //     })
    //     this.relations.splice(diff + 1, 0, new Array(this.all.length).fill(0))
    //   } else if (old.length > val.length) {
    //     const diff = old.findIndex((eleOld) => val.every((eleNew) => eleOld.id !== eleNew.id))
    //     this.relations.forEach((ele) => {
    //       ele.splice(diff + 1, 1)
    //     })
    //     this.relations.splice(diff + 1, 1)
    //   }
    //   this.$emit('change', this.relations)
    // },
  },
  mounted() {},
  methods: {
    isRelated(i, j) {
      return this.relations[i][j] === 1 && this.relations[j][i] === 1
    },
    isUnrelated(i, j) {
      return this.relations[i][j] !== 1 || this.relations[j][i] !== 1
    },
    relationChange(i, j) {
      let next = 1
      const current = this.relations[i][j]
      if (current !== 0) {
        next = -this.relations[i][j]
      }
      this.$set(this.relations[i], j, next)
      if (this.isRelated(i, j)) {
        this.$emit('related', {
          from: this.all[i].id,
          to: this.all[j].id,
          lineShape: 1,
          text: '',
          isHideArrow: false,
        },{
          from: this.all[j].id,
          to: this.all[i].id,
          lineShape: 1,
          text: '',
          isHideArrow: false,
        })
      } else if (this.isUnrelated(i, j)) {
        this.$emit('unrelated', { from: this.all[i].id, to: this.all[j].id }, { from: this.all[j].id, to: this.all[i].id })
      } else {
        this.$emit('relation-changed', this.relations)
      }
      this.$emit('change', this.relations)
    },
    // 点击占位空格时聚焦可编辑元素
    clickSeat() {
      this.$refs.value && this.$refs.value.focus()
    },
    // 聚焦时定位光标位置
    focus() {
      setTimeout(() => {
        this.setFocus(this.inputValue.length)
      }, 0)
    },
    // 失焦时再赋值一遍
    blur() {
      this.inputValue = this.$refs.value.innerText
    },
    // 输入时进行赋值
    input() {
      this.inputValue = this.$refs.value.innerText
    },
    // 聚焦后把光标放到最后
    setFocus(index) {
      let obj = this.$refs.value

      if (document.createRange) {
        // 获取选定对象
        let selection = getSelection()
        let range = document.createRange()

        range.setStart(obj.firstChild || obj, index)
        range.setEnd(obj.firstChild || obj, index)
        selection.removeAllRanges()
        selection.addRange(range)
      }
    },
  },
}
</script>
<style scoped>
.value {
  color: #0000ff;
}
</style>
