<template>
  <div>
    <a
      @click="$vuetify.goTo(goto, { duration: 1000, offset: 100, easing: 'easeInOutCubic' })"
      @mouseenter="show = true"
      @mouseleave="show = false"
    >
      <strong>{{ label }}</strong>
    </a>
    <span v-show="show"><v-icon large right color="blue" dark>mdi-pound</v-icon></span>
  </div>
</template>
<script>
export default {
  props: {
    label: {
      require: true,
      type: String,
      default: '',
    },
    goto: {
      require: true,
      type: String,
      default: '',
    },
  },
  data() {
    return {
      show: false,
    }
  },
}
</script>
<style scoped>
a {
  color: black;
}
span {
  color: royalblue;
}
</style>
