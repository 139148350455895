var render = function render(){var _vm=this,_c=_vm._self._c;return _c('table',[_c('tr',{staticStyle:{"height":"50px"}},[(!_vm.locked)?_c('th',[_vm._v("选择")]):_vm._e(),_c('th',[_vm._v("编号")]),_c('th',[_vm._v("方案简介")]),_c('th',[_vm._v("成本优势")]),_c('th',[_vm._v("复杂度优势")]),_c('th',[_vm._v("可行性")]),_c('th',[_vm._v("处理效果")]),_c('th',[_vm._v("总计")])]),_vm._l((_vm.solutionScores.filter(ele => {
        return _vm.all?.some(solution => solution.id == ele.id)
      })),function(solution,i){return _c('tr',{key:i},[_c('td',{directives:[{name:"show",rawName:"v-show",value:(!_vm.locked),expression:"!locked"}],staticClass:"Triz_checkbox",staticStyle:{"width":"60px"}},[_c('triz-validator',{attrs:{"rules":`selectSome:${_vm.solutionScores.filter((ele) => ele.checked).length}`,"id":"方案评价表-选择框"}},[_c('v-checkbox',{attrs:{"value":solution.id},on:{"change":_vm.change},model:{value:(solution.checked),callback:function ($$v) {_vm.$set(solution, "checked", $$v)},expression:"solution.checked"}})],1)],1),_c('td',{staticStyle:{"text-align":"center","width":"80px"}},[_vm._v("方案"+_vm._s(i + 1))]),_c('td',{staticStyle:{"width":"200px"}},[_c('div',{domProps:{"innerHTML":_vm._s(
          _vm.all.find((ele) => ele.id === solution.id)
            ? _vm.all
                .find((ele) => ele.id === solution.id)
                .solutionDetail.replace(/<[^>]+>/g, '')
            : ''
        )}})]),_c('td',[_c('div',{staticClass:"Triz_Select-table",staticStyle:{"width":"100px"}},[_c('triz-validator',{attrs:{"rules":"required","id":"方案评价表-成本优势打分"}},[_c('triz-select',{attrs:{"solo":"","items":_vm.scores,"label":"请打分"},on:{"change":_vm.change},model:{value:(solution.priceScore),callback:function ($$v) {_vm.$set(solution, "priceScore", $$v)},expression:"solution.priceScore"}})],1)],1)]),_c('td',[_c('div',{staticClass:"Triz_Select-table",staticStyle:{"width":"100px"}},[_c('triz-validator',{attrs:{"rules":"required","id":"方案评价表-复杂度优势打分"}},[_c('triz-select',{attrs:{"solo":"","items":_vm.scores,"label":"请打分"},on:{"change":_vm.change},model:{value:(solution.complexScore),callback:function ($$v) {_vm.$set(solution, "complexScore", $$v)},expression:"solution.complexScore"}})],1)],1)]),_c('td',[_c('div',{staticClass:"Triz_Select-table",staticStyle:{"width":"100px"}},[_c('triz-validator',{attrs:{"rules":"required","id":"方案评价表-可行性打分"}},[_c('triz-select',{attrs:{"solo":"","items":_vm.scores,"label":"请打分"},on:{"change":_vm.change},model:{value:(solution.implementScore),callback:function ($$v) {_vm.$set(solution, "implementScore", $$v)},expression:"solution.implementScore"}})],1)],1)]),_c('td',[_c('div',{staticClass:"Triz_Select-table",staticStyle:{"width":"100px"}},[_c('triz-validator',{attrs:{"rules":"required","id":"方案评价表-处理效果打分"}},[_c('triz-select',{attrs:{"solo":"","items":_vm.scores,"label":"请打分"},on:{"change":_vm.change},model:{value:(solution.effectScore),callback:function ($$v) {_vm.$set(solution, "effectScore", $$v)},expression:"solution.effectScore"}})],1)],1)]),_c('td',[(!isNaN(_vm.average(solution)))?_c('div',{staticStyle:{"color":"red"}},[_c('strong',[_vm._v(_vm._s(_vm.average(solution)))])]):_vm._e()])])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }