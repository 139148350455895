<template>
  <div style="height: 800px; width: auto; top: 100px">
    <div v-if="locked" style="position: absolute; width: auto; height: 800px; z-index: 99"></div>
    <v-img
      v-if="!clip"
      style="position: absolute; border: 1px"
      height="100px"
      width="100px;"
      contain
      src="../assets/function.svg"
    />
    <!-- <v-bottom-sheet
      v-if="currLineObject"
      :value="showMenu"
      inset
      :hide-overlay="false"
      :persistent="showMenu"
      :retain-focus="false"
    >
      <v-card style="padding: 30px; padding-bottom: 0; width: auto">
        <v-text-field
          label="作用名"
          v-model="currLineObject.relations[0].text"
          outlined
          dense
          @input="changeText"
        ></v-text-field>
        <v-select
          dense
          outlined
          label="作用类型"
          v-model="currLineObject.relations[0].lineShape"
          flat
          :items="arrowTypes"
          @change="changeShape"
        >
        </v-select>
        <v-btn fab dark small @click="changeReserve"><v-icon>mdi-rotate-3d-variant</v-icon></v-btn>
        {{ currLineObject.fromNode.text }}
        <v-icon>mdi-ray-start-arrow</v-icon>
        {{ currLineObject.toNode.text }}
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="showMenu = false">关闭</v-btn>
        </v-card-actions>
      </v-card>
    </v-bottom-sheet> -->
    <RelationGraph
      ref="seeksRelationGraph"
      :after="after"
      :options="graphOptions"
      :current="current"
      :all-clips="allClips"
      :on-node-click="onNodeClick"
      :on-line-click="onLineClick"
      :on-label-click="onLabelClick"
      :on-drag="change"
      :dragable="dragable"
      :clickable="clickable"
      class="none_Icon"
    >
      <template
        v-if="!after || !current.selected.map((select) => select.id).includes(node.id)"
        slot="node"
        slot-scope="{ node }"
      >
        <v-card
          class="d-flex align-center color000"
          :class="node.data.super ? (node.data.isNo1 ? 'backImgNo1' : 'backImg') : 'backImg2'"
          :color="node.data.super ? '' : 'teal darken-1'"
          max-width="170"
          min-width="100"
          min-height="50"
          dark
          :label="!node.data.super"
          :filter="node.data.super"
        >
          <span style="font-size: 16px" class="ma-2">{{ node.text }}</span>
          <v-icon
            v-if="clip && current.selected.map((select) => select.id).includes(node.id)"
            x-large
            dark
            color="red"
            style="position: absolute"
            >mdi-close</v-icon
          >
        </v-card>
      </template>
    </RelationGraph>
  </div>
</template>

<script>
import RelationGraph from './Graph/src/index.vue'
import { lineShapes, lineShapeList } from '../store/default'

export default {
  name: 'Demo',
  components: { RelationGraph },
  props: {
    relationGraphData: {
      type: Object,
      default: () => {},
    },
    dragable: {
      type: Boolean,
      default: true,
    },
    clickable: {
      type: Boolean,
      default: true,
    },
    clip: {
      type: Boolean,
      default: true,
    },
    after: {
      type: Boolean,
      default: false,
    },
    current: {
      type: Object,
      default: () => {},
    },
    allClips: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      graphOptions: {
        allowShowSettingPanel: true,
        allowShowMiniToolBar: true,
        allowSwitchLineShape: true,
        allowSwitchJunctionPoint: true,
        defaultJunctionPoint: 'border',
        moveToCenterWhenResize: true,
        defaultFocusRootNode: true,
        defaultNodeColor: 'rgba(254, 253, 253, 0)',
        defaultNodeFontColor: 'rgba(0, 0, 0, 0)',
        defaultNodeBorderColor: 'rgba(0, 0, 0, 0)',
        defaultNodeBorderWidth: 1,
        defaultNodeWidth: 100,
        defaultNodeHeight: 50,
        defaultNodeShape: 1,
        defaultLineColor: 'rgba(0, 0, 0, 1)',
        defaultLineWidth: 2,
        defaultLineShape: 1,
        // defaultLineMarker: 1,
        defaultShowLineLabel: true,
        // 这里可以参考"Graph 图谱"中的参数进行设置
        layouts: [
          // {
          //   label: '手工',
          //   layoutName: 'fixed',
          //   layoutClassName: 'seeks-layout-fixed',
          //   defaultJunctionPoint: 'border',
          //   defaultNodeShape: 0,
          //   defaultLineShape: 1,
          // },
          {
            label: '中心',
            layoutName: 'center',
            layoutClassName: 'seeks-layout-center',
            from: 'left',
            min_per_width: '100',
            max_per_width: '250',
            min_per_height: '100',
            max_per_height: '100',
            centerOffset_x: '500',
            centerOffset_y: '0',
            distance_coefficient: 0.6,
          },
        ],
      },
      showMenu: false,
      x: 0,
      y: 0,
      currLineObject: null,
      arrowTypes: [
        { text: '正常功能', value: lineShapes.normal },
        { text: '不足功能', value: lineShapes.unsatisfy },
        { text: '过量功能', value: lineShapes.over },
        { text: '有害功能', value: lineShapes.bad },
      ],
    }
  },
  model: {
    prop: 'relationGraphData',
    event: 'change',
  },
  mounted() {
    // this.showSeeksGraph()
  },
  watch: {
    relationGraphData() {
      if (!this.currLineObject) return
      const lines = this.$refs.seeksRelationGraph.getLines()
      this.currLineObject = lines.find(
        (line) =>
          line.fromNode.id === this.currLineObject.fromNode.id && line.toNode.id === this.currLineObject.toNode.id
      )
    },
    locked() {
      this.showMenu = false
    },
  },
  computed: {
    locked() {
      return this.$store.state.locked
    },
  },
  methods: {
    setJsonData(jsonData) {
      jsonData.nodes.forEach((node) => {
        node.flated = false
      })
      this.$refs.seeksRelationGraph.setJsonData(jsonData, () => {
        this.$refs.seeksRelationGraph.refresh()
      })
    },
    changeNodeText(node) {
      // console.log('graph update', node)
      this.$refs.seeksRelationGraph.getNodes().find((ele) => ele.id === node.id).text = node.text
      this.change()
    },
    appendJsonData(jsonData) {
      // console.log('graph update', jsonData)
      this.$refs.seeksRelationGraph.appendJsonData(jsonData, () => {
        this.$refs.seeksRelationGraph.refresh()
        this.change()
      })
    },
    removeNodeById(nodeId) {
      this.$refs.seeksRelationGraph.removeNodeById(nodeId)
      this.$refs.seeksRelationGraph.refresh()
      this.change()
    },
    removeRelation(relation) {
      // console.log('removeRelation')
      const links = this.$refs.seeksRelationGraph.getLines()
      let link = links.find(
        (ele) =>
          (ele.fromNode.id === relation.from && ele.toNode.id === relation.to) ||
          (ele.fromNode.id === relation.to && ele.toNode.id === relation.from)
      )
      if (link) {
        const index = links.findIndex(
          (ele) =>
            (ele.fromNode.id === relation.from && ele.toNode.id === relation.to) ||
            (ele.fromNode.id === relation.to && ele.toNode.id === relation.from)
        )
        this.$refs.seeksRelationGraph.graphData.lines.splice(index, 1)
        delete this.$refs.seeksRelationGraph.graphData.lines_map[link.seeks_id]
        links.splice(index, 1)
        this.$refs.seeksRelationGraph.refresh()
        this.change()
      }
    },
    changeReserve() {
      this.currLineObject.relations[0].isReverse = !this.currLineObject.relations[0].isReverse
      const fromNode = { ...this.currLineObject.fromNode }
      const toNode = { ...this.currLineObject.toNode }
      this.currLineObject.fromNode = toNode
      this.currLineObject.toNode = fromNode
      this.currLineObject.relations[0].isHideArrow = false
      this.change()
    },
    changeText() {
      this.change()
    },
    changeShape() {
      this.change()
    },
    change() {
      const jsonData = this.$refs.seeksRelationGraph.getGraphJsonData()
      console.log('graph json data changed:', jsonData)
      this.$emit('graph-data-change', jsonData)
    },
    onNodeClick(nodeObject) {
      const index = this.current.selected.findIndex((select) => select.id === nodeObject.id)
      if (index === -1) {
        this.current.selected.push({
          id: nodeObject.id,
        })
      } else {
        this.current.selected.splice(index, 1)
      }
      this.$emit('clip-data-change', this.current)
    },
    onLineClick() {},
    onLabelClick(thisLine) {
      console.log('onLabelClick', this.relationGraphData)
      const lines = this.$refs.seeksRelationGraph.getLines()

      this.currLineObject = lines.find(
        (line) => line.fromNode.id === thisLine.fromNode.id && line.toNode.id === thisLine.toNode.id
      )
      if (!this.currLineObject) return
      const index = this.currLineObject.relations[0].lineShapeType || 0
      this.currLineObject.relations[0].lineShapeType = (index + 1) % lineShapeList.length
      this.change()
    },
    show(lineObject) {
      if (this.locked) return
      this.x = 0
      this.y = 0
      this.currLineObject = lineObject
      this.$nextTick(() => {
        this.showMenu = true
      })
    },
  },
}
</script>
<style lang="scss">
.color000 {
  color: #fff !important;
}
.backImg2 {
  box-shadow: none !important;
  position: relative;
}
.backImg {
  position: relative;
  padding: 0px 20px;
  background-color: #fff !important;
  box-shadow: none !important;
  background-image: url('../assets/sixbg.png');
  background-size: 100% 100%;
  align-self: center;
  align-items: center;
}
.backImgNo1 {
  position: relative;
  padding: 0px 20px;
  background-color: #fff !important;
  box-shadow: none !important;
  background-image: url('../assets/sixbgtop.png');
  background-size: 100% 100%;
  border-radius: 25px !important;
  align-self: center;
  align-items: center;
}
.none_Icon {
  .rel-node-shape-1 {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
  }
}
</style>
