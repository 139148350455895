<template>
  <table>
    <tr style="height: 50px">
      <th>问题</th>
      <th>分析结果</th>
    </tr>
    <tr v-for="(idealResolve, i) in idealResolveAnalyze" :key="i">
      <td style="height: 50px; text-align: center; width: 320px">{{ idealResolve.question }}</td>
      <td class="Triz_textarea">
        <triz-validator rules="required" id="最终理想解-分析结果">
          <triz-textarea v-model="idealResolve.answer" @input="change" />
        </triz-validator>
      </td>
    </tr>
  </table>
</template>
<script>
export default {
  props: {
    idealResolveAnalyze: {
      type: Array,
      default: () => [],
    },
  },
  model: {
    prop: 'idealResolveAnalyze',
    event: 'change',
  },
  data() {
    return {}
  },
  // watch: {
  //   idealResolveAnalyze: {
  //     handler(val) {
  //       this.$emit('change', val)
  //     },
  //     deep: true,
  //   },
  // },
  mounted() {},
  methods: {
    change() {
      this.$emit('change', this.idealResolveAnalyze)
    },
  },
}
</script>
<style scoped></style>
