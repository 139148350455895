<template>
  <table>
    <tr style="height: 80px">
      <th>序号</th>
      <th>功能载体</th>
      <th>功能</th>
      <th>功能对象</th>
      <th>功能性质</th>
      <th>功能等级</th>
      <th>执行程度</th>
      <th>得分</th>
      <th>总分</th>
      <th v-show="!locked">操作</th>
    </tr>
    <template v-for="(node, i) in nodes">
      <tr style="text-align: center" :key="i">
        <td :colspan="locked ? 9 : 10">
          {{ node.text }}
          <v-btn
            v-show="!locked && relations.filter((ele) => ele.from == node.id).some(ele => !ele.isExpanded && ele.isHide)"
            dense
            class="ma-2"
            style="margin-left: 20px; border-radius: 4px"
            tile
            outlined
            color="success"
            @click="expandHideRelation(node.id)"
          >
            <v-icon left>mdi-plus</v-icon>展开
          </v-btn>
          <v-btn
            v-show="!locked && relations.filter((ele) => ele.from == node.id).some(ele => ele.isExpanded && ele.isHide)"
            dense
            class="ma-2"
            style="margin-left: 20px; border-radius: 4px"
            tile
            outlined
            color="red"
            @click="expandHideRelation(node.id)"
          >
            <v-icon left>mdi-minus</v-icon>收起
          </v-btn>
        </td>
      </tr>

      <template v-for="(relation, j) in relations.filter((ele) => ele.from == node.id).filter(ele => ele.isExpanded || !ele.isHide)">
        <tr v-if="relation.isExpanded || !relation.isHide" :key="`${i}-${j}`">
           <td class="Triz_textarea" >
            {{ j + 1 }}
          </td>
          <td v-if="j==0" class="Triz_textarea" :rowspan="computeRowspan(node)">
            {{ nodes.find(node => node.id == relation.from).text  }}
          </td>
          <td class="Triz_textarea">
            <triz-validator rules="required" id="功能分析-功能表-功能">
              <triz-textarea v-model="relation.text" @input="relationChange"></triz-textarea>
            </triz-validator>
          </td>
          <td class="Triz_textarea">
              {{ nodes.find(node => node.id == relation.to).text  }}
          </td>
          <td class="Triz_Select-table">
            <triz-validator rules="required" id="功能分析-功能表-功能性质">
              <triz-select v-model="relation.performance" flat :items="qualityTypes" solo @change="changePerformance(relation)"> </triz-select>
            </triz-validator>
          </td>
          <td class="Triz_Select-table">
            <triz-validator v-if="relation.performance != '有害'" rules="required" id="功能分析-功能表-功能等级">
              <triz-select v-model="relation.level" flat :items="functionScores" solo @change="changeScore(relation)">
              </triz-select>
            </triz-validator>
          </td>
          <td class="Triz_Select-table">
            <triz-validator v-if="relation.performance != '有害'" rules="required" id="功能分析-功能表-执行程度">
              <triz-select v-model="relation.lineShape" flat :items="excutionTypes" solo @change="relationChange(relation)"> </triz-select>
            </triz-validator>
          </td>
          <td>
            {{ relation.score }}
          </td>
          <td
            v-if="j === 0"
            :rowspan="computeRowspan(node)"
            style="text-align: center"
          >
           {{relations.filter((ele) => ele.from == node.id).filter(ele => ele.isExpanded || !ele
           	.isHide).map((ele) => ele.score || 0).reduce((a, b) => a + b, 0)}}
          </td>
          <td v-show="!locked">
            <v-btn v-if="!relation.isHide" dense class="ma-2" tile outlined color="error" @click="removeRalation(relation)">
              <v-icon left>mdi-minus</v-icon> 删除
            </v-btn>
            <v-btn v-if="relation.isHide" dense class="ma-2" tile outlined color="success" @click="recoverRalation(relation)">
              <v-icon left>mdi-plus</v-icon> 恢复
            </v-btn>
          </td>
        </tr>
      </template>
    </template>
  </table>
</template>
<script>
import { functionScores, qualityTypes, excutionTypes, lineShapes } from '../store/default'

export default {
  props: {
    nodes: {
      type: Array,
      default: () => [],
    },
    relations: {
      type: Array,
      default: () => [],
    },
    functions: {
      type: Array,
      default: () => [],
    },
  },
  model: {
    prop: 'functions',
    event: 'change',
  },
  data() {
    return {
      functionScores,
      qualityTypes,
      excutionTypes,
      lineShapes,
    }
  },
  computed: {
    // 占位空格内容
    all() {
      return []
    },
    locked() {
      return this.$store.state.locked
    },
  },
  mounted() {},
  methods: {
    expandHideRelation(nodeId) {
      this.relations.forEach((relation) => {
        if (relation.from == nodeId) {
          relation.isExpanded = !relation.isExpanded
        }
      })
      this.$emit('relation', this.relations)
    },
    removeRalation(relation) {
      relation.isHide = true
      relation.score = 0
      this.$emit('relation', this.relations)
    },
    recoverRalation(relation) {
      relation.isHide = false
      relation.score = functionScores.find((ele) => ele.value == relation.level).score
      this.$emit('relation', this.relations)
    },
    change() {
      this.$emit('change')
    },
    relationChange(relation) {
      if (relation.lineShape == this.lineShapes.bad || relation.lineShape == this.lineShapes.unsatisfy) {
        relation.problem = ''
      } else {
        delete relation.problem
      }
      this.$emit('relation', this.relations)
    },
    computeRowspan(node) {
      const rowspan = this.relations.filter((ele) => ele.from == node.id).filter(ele => ele.isExpanded || !ele.isHide)
      // console.log('computeRowspan', rowspan)
      return rowspan.length
    },
    changePerformance(relation) {
      if (relation.performance == '有害') {
        relation.lineShape = lineShapes.bad
        relation.rule = '有害功能'
      } else {
        relation.lineShape = undefined
        relation.rule = undefined
      }
      this.relationChange(relation)
    },
    changeScore(relation) {
      relation.score = functionScores.find((ele) => ele.value == relation.level).score
      this.relationChange(relation)
    },
  },
}
</script>
<style scoped></style>
